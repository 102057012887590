import React from "react";
import Contact from "./Contact";
import NavBar from "./NavBar";
import Pricing from "./Pricing";
import Team from "./Team";

// Using Layout so we can wrap things into _app.js so component can be mounted on every page
const Layout = ({ children }) => {
  return (
    <div>
      <NavBar />
      {children}
      <Pricing />
      <Team />
      <Contact />
    </div>
  );
};

export default Layout;
