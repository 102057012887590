import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
import Layout from "../components/Layout";

// markup
const IndexPage = () => {
  return (
    <>
      <Layout>
        <div className="text-gray-400 bg-primary-100  body-font overflow-hidden">
          <div className="container mx-auto flex px-5 py-24 md:flex-row flex-col items-center">
            <div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6 md:mb-0 mb-10">
              <StaticImage
                className="object-cover object-center rounded"
                alt="hero"
                src="../images/linkhub.svg"
                placeholder="blurred"
              />
            </div>
            <div className="lg:flex-grow md:w-1/2 lg:pl-24 md:pl-16 flex flex-col md:items-start md:text-left items-center text-center">
              <h1 className="title-font sm:text-4xl text-3xl mb-4 font-medium text-white">
                Link Hub
                <br className="hidden lg:inline-block" />
                One Link You Need
              </h1>
              <p className="mb-8 leading-relaxed">
                Build a simple and free online homepage for your links, content
                and community.
              </p>
              <div className="flex justify-center">
                <button className="inline-flex text-white bg-pink-500 border-0 py-2 px-6 focus:outline-none hover:bg-pink-600 rounded text-lg">
                  Sing Up
                </button>
                <button className="ml-4 inline-flex text-gray-400 bg-gray-800 border-0 py-2 px-6 focus:outline-none hover:bg-pink-700 hover:text-white rounded text-lg">
                  Join Waiting List
                </button>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default IndexPage;
